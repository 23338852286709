import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";

// eslint-disable-next-line
export const IndustryChildTemplate = ({ title, header, content, image, contentComponent }) => {
  const PageContent = contentComponent || Content;

  return (
    <section className="section section--gradient" style={{
      backgroundImage:"url(/img/globe-bg.jpg)",
      color: "white",
    }}>
      <div className="container">
        <div className="columns">
            <div className="column is-8 is-mobile">
              <div className="section">
                <h2 className="title is-size-3 has-text-weight-bold is-bold-light" style={{
                  color: "#30CAFF",
                }}>
                  {header}
                </h2>
                  <div className="section is-inline-block" style={{
                    paddingTop: "0em",
                    paddingLeft: "0em",
                    paddingRight: "0em",
                    paddingBottom: "3em",
                  }}>
                  <PreviewCompatibleImage imageInfo={image} />
                  </div>
                <PageContent className="content" content={content} />
                <br></br>
                <p><a href="/contact" style={{
                  color: "#FF6633",
                }}>Get in touch today</a> or go back to <a href="/industries" style={{
                  color: "#FF6633",
                }}>Industries.</a></p>
              </div>
            </div>
        </div>
      </div>
    </section>
  );
};

IndustryChildTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const IndustryChild = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <IndustryChildTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        header={post.frontmatter.header} 
        image={post.frontmatter.image}
        path={post.frontmatter.path}
        content={post.html}
      />
    </Layout>
  );
};

IndustryChild.propTypes = {
  data: PropTypes.object.isRequired,
};

export default IndustryChild;

export const industryChildQuery = graphql`
  query IndustryChild($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        header
        path
        image {
          childImageSharp {
            gatsbyImageData(width: 750, quality: 100, layout: CONSTRAINED)
          }
        }
      }
    }
  }
`;
